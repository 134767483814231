'use client';

import { checkFrmCookieToGetToken } from '@/utils';
import React, { useEffect } from 'react';
import { useRouter } from 'next/navigation';
import { useAppDispatch } from '@/lib/hooks';
import { loginAction } from '@/lib/features/userSlice';

const ClientCheckCookie: React.FC = () => {
  const router = useRouter();
  const dispatch = useAppDispatch();
  useEffect(() => {
    const res = checkFrmCookieToGetToken();
    if (res === 'noCookie') {
      router.push('/login');
    } else {
      if (window.location.pathname === '/' || window.location.pathname === '/en') {
        router.push('/dashboard');
      }
      dispatch(loginAction());
    }
  }, []);

  return <></>;
};

export default ClientCheckCookie;
